<template>
  <div v-if="localImg">
    <div class="q-parallax" :style="`height: calc(${imgHeight})`">
      <div class="q-parallax__media absolute-full overflow-hidden">
        <div
          :class="`tepa-hero-bg-extra-tall ${
            tag === 'Banner' ? 'zoomIn' : ''
          } ${isMobile ? 'mobile' : 'desktop'}`"
          :style="{
            backgroundImage:
              `linear-gradient(
      180deg,
      rgba(0, 0, 0, ${opacity ? opacity : 0}),
      rgba(0, 0, 0, ${opacity ? opacity : 0})
    ),url(` +
              localImg +
              ')',
            height: `calc(${imgHeight})`,
            padding: newLine ? '70px 0 0 0' : '',
          }"
        />
      </div>
      <div
        class="q-parallax__content absolute-full flex-center"
        style="height: 100%"
      >
        <span
          v-if="localText"
          :id="`parallax-${localText.replaceAll(' ', '-')}`"
          class="tepa-parallax-text animate__animated animate__fadeInUp"
          :style="`white-space:${
            newLine ? 'pre' : ''
          };font-size:${fontSize}px;font-weight:${localFontWeight};${
            imgHeight === '100vh' && fontSize === 30
              ? `${state ? `position: absolute;bottom: ${state};` : ''}`
              : ''
          }`"
          >{{ localText }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: [
    "tag",
    "text",
    "img",
    "imgHeight",
    "fontSize",
    "fontWeight",
    "newLine",
    "state",
    "opacity",
    "zIndex",
  ],
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
    isMobile() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
  data() {
    return {
      localImg: null,
      localText: null,
      index: 0,
      localFontWeight: null,
    };
  },
  mounted() {
    const me = this;
    this.localFontWeight = this.fontWeight ? this.fontWeight : "bold";
    if (this.img) {
      this.localImg = this.img;
      this.localText = this.text;
    } else {
      this.getParallax();
    }
  },
  methods: {
    async getParallax() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["${this.tag}"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data;
        if (data.body) {
          this.localImg = data.body[this.index].primary.image.url;
          this.localText =
            data.body[this.index].primary.title.length > 0
              ? data.body[this.index].primary.title[0].text
              : null;
          this.SlideshowImage(data);
        } else {
          this.localImg = data.img.url;
          this.localText = data.name.length > 0 ? data.name[0].text : null;
        }
      }
    },
    SlideshowImage(list) {
      const me = this;
      if (list.body.length > 1) {
        setInterval(() => {
          this.index += 1;
          if (this.index > list.body.length - 1) {
            this.index = 0;
          }
          let image = $("#parallax");
          image.fadeOut(1000, function () {
            image.css(
              "background-image",
              `linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.4)
        ),url(${list.body[me.index].primary.image.url})`
            );
            me.localText =
              list.body[me.index].primary.title.length > 0
                ? list.body[me.index].primary.title[0].text
                : null;
            image.fadeIn(1000);
          });
        }, list.delay);
      }
    },
  },
};
</script>

<style scoped>
@keyframes slideInFromLeft {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
/* .q-parallax__media{
  animation: slideInFromLeft 2s ease 0.8s forwards;
} */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.absolute-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.q-parallax {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: inherit;
}
.parallax-container {
  position: relative;
}
.tepa-parallax-text {
  font-family: Cormorant, serif;
  white-space: break-spaces;
  font-style: normal;
  text-align: center;
  color: #f2f2f2;
}
.overflow-hidden {
  overflow: hidden !important;
}
.zoomIn {
  animation: slideInFromLeft 2s ease 0.8s forwards;
}
.tepa-hero-bg-extra-tall {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-position: 0px 0px, 50% 50%;
  /* background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed; */
}
.mobile {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: unset;
}
.desktop {
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}
@media only screen and (max-width: 992px) {
  .tepa-hero-bg-extra-tall {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: unset;
  }
}
</style>