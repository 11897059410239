var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.localImg)?_c('div',[_c('div',{staticClass:"q-parallax",style:(("height: calc(" + _vm.imgHeight + ")"))},[_c('div',{staticClass:"q-parallax__media absolute-full overflow-hidden"},[_c('div',{class:("tepa-hero-bg-extra-tall " + (_vm.tag === 'Banner' ? 'zoomIn' : '') + " " + (_vm.isMobile ? 'mobile' : 'desktop')),style:({
          backgroundImage:
            "linear-gradient(\n    180deg,\n    rgba(0, 0, 0, " + (_vm.opacity ? _vm.opacity : 0) + "),\n    rgba(0, 0, 0, " + (_vm.opacity ? _vm.opacity : 0) + ")\n  ),url(" +
            _vm.localImg +
            ')',
          height: ("calc(" + _vm.imgHeight + ")"),
          padding: _vm.newLine ? '70px 0 0 0' : '',
        })})]),_c('div',{staticClass:"q-parallax__content absolute-full flex-center",staticStyle:{"height":"100%"}},[(_vm.localText)?_c('span',{staticClass:"tepa-parallax-text animate__animated animate__fadeInUp",style:(("white-space:" + (_vm.newLine ? 'pre' : '') + ";font-size:" + _vm.fontSize + "px;font-weight:" + _vm.localFontWeight + ";" + (_vm.imgHeight === '100vh' && _vm.fontSize === 30
            ? ("" + (_vm.state ? ("position: absolute;bottom: " + _vm.state + ";") : ''))
            : ''))),attrs:{"id":("parallax-" + (_vm.localText.replaceAll(' ', '-')))}},[_vm._v(_vm._s(_vm.localText))]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }